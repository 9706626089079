@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');

:root {
    --first-color: #381f1b;
    --second-color: #ede8ea;
}

html, body {
    margin: 0;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: var(--first-color);
}

#header {
    margin-bottom: 2.5em;
}

#header-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2.5em;
    font-style: italic;
    font-weight: 700;
    margin: 0;
}

.icon-wrapper {
    margin: 0 10px 0 10px;
}

.icon {
    height: 22px;
    width: 22px;
}

#player {
    margin-top: 1.3em;
}

svg {
    fill: var(--first-color);
}

.disabled {
    fill: var(--second-color);
    cursor: not-allowed !important;
}

/* animation */
/* starting ENTER animation */
.pan-right-enter {
    opacity: 0;
    transform: translateX(-4em);
}
  
/* ending ENTER animation */
.pan-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms;
}

/* starting EXIT animation */
.pan-right-exit {
    opacity: 1;
    transform: translateX(0);
}

/* ending EXIT animation */
.pan-right-exit-active {
    opacity: 0;
    transform: translateX(4em);
    transition: all 500ms;
}

/* hide scrollbars but keep functionality (for mobile) */
#description::-webkit-scrollbar { /* Chrome, Safari, Opera */
    display: none;
}

#description {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}