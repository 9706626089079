#cover {
    --cover-size: 200px;
    --vinyl-size: 196px;
    --vinyl-offset: 82px; /* from left */
    height: var(--cover-size);
    width: calc(var(--cover-size) + var(--vinyl-offset) - (var(--cover-size) - var(--vinyl-size)) / 2); /* assuming cover-size > vinyl-size */
    display: inline-block;
    position: relative;
}

#coverImage {
    height: var(--cover-size);
    width: var(--cover-size);
    position: absolute;
    left: 0;
    z-index: 1;
    transition-delay: 150ms; /* for animation */
}

#vinylImage {
    height: var(--vinyl-size);
    width: var(--vinyl-size);
    position: absolute;
    top: calc((var(--cover-size) - var(--vinyl-size)) / 2);
    left: var(--vinyl-offset);
    z-index: -1;
}