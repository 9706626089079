#play {
    height: 50px;
    width: 50px;
    vertical-align: middle;
}

#prev, #next {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}

#prev {
    cursor: w-resize;
    margin-right: 3em;
}

#next {
    cursor: e-resize;
    margin-left: 3em;
}