@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@300&display=swap);
:root {
    --first-color: #381f1b;
    --second-color: #ede8ea;
}

html, body {
    margin: 0;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #381f1b;
    color: var(--first-color);
}

#header {
    margin-bottom: 2.5em;
}

#header-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2.5em;
    font-style: italic;
    font-weight: 700;
    margin: 0;
}

.icon-wrapper {
    margin: 0 10px 0 10px;
}

.icon {
    height: 22px;
    width: 22px;
}

#player {
    margin-top: 1.3em;
}

svg {
    fill: #381f1b;
    fill: var(--first-color);
}

.disabled {
    fill: #ede8ea;
    fill: var(--second-color);
    cursor: not-allowed !important;
}

/* animation */
/* starting ENTER animation */
.pan-right-enter {
    opacity: 0;
    -webkit-transform: translateX(-4em);
            transform: translateX(-4em);
}
  
/* ending ENTER animation */
.pan-right-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all 500ms;
}

/* starting EXIT animation */
.pan-right-exit {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

/* ending EXIT animation */
.pan-right-exit-active {
    opacity: 0;
    -webkit-transform: translateX(4em);
            transform: translateX(4em);
    transition: all 500ms;
}

/* hide scrollbars but keep functionality (for mobile) */
#description::-webkit-scrollbar { /* Chrome, Safari, Opera */
    display: none;
}

#description {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
#cover {
    --cover-size: 200px;
    --vinyl-size: 196px;
    --vinyl-offset: 82px; /* from left */
    height: var(--cover-size);
    width: calc(var(--cover-size) + var(--vinyl-offset) - (var(--cover-size) - var(--vinyl-size)) / 2); /* assuming cover-size > vinyl-size */
    display: inline-block;
    position: relative;
}

#coverImage {
    height: var(--cover-size);
    width: var(--cover-size);
    position: absolute;
    left: 0;
    z-index: 1;
    transition-delay: 150ms; /* for animation */
}

#vinylImage {
    height: var(--vinyl-size);
    width: var(--vinyl-size);
    position: absolute;
    top: calc((var(--cover-size) - var(--vinyl-size)) / 2);
    left: var(--vinyl-offset);
    z-index: -1;
}
#description {
    width: 20em;
    height: 200px;
    text-align: left;
    padding: 1em;
    box-sizing: border-box;
    overflow: scroll;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

#title {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition-delay: 100ms; /* for animation */
}

#subtitle {
    margin: 0.2em 1em 1em 1em;
    transition-delay: 200ms; /* for animation */
}

#content {
    margin: 0 0 0 1em;
    transition-delay: 300ms; /* for animation */
}
#cover {
    display: inline-block;
}

#description {
    display: inline-block;
    vertical-align: top;
    margin-left: 1em;
}
#play {
    height: 50px;
    width: 50px;
    vertical-align: middle;
}

#prev, #next {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}

#prev {
    cursor: w-resize;
    margin-right: 3em;
}

#next {
    cursor: e-resize;
    margin-left: 3em;
}
