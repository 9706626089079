@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@300&display=swap');

#description {
    width: 20em;
    height: 200px;
    text-align: left;
    padding: 1em;
    box-sizing: border-box;
    overflow: scroll;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

#title {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition-delay: 100ms; /* for animation */
}

#subtitle {
    margin: 0.2em 1em 1em 1em;
    transition-delay: 200ms; /* for animation */
}

#content {
    margin: 0 0 0 1em;
    transition-delay: 300ms; /* for animation */
}